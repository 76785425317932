import { BifoldButton } from "src/ui/BifoldButton/BifoldButton"
import { CodeIcon } from "src/ui/Icon/CodeIcon"
import { Icon } from "src/ui/Icon/Icon"
import { graphql } from "src/api/graphql/gql"
import { ApolloError, useMutation } from "@apollo/client"
import { useJobByID } from "src/common/hooks/useJobByID"
import { useEffect, useState } from "react"
import { notification } from "antd"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { SplitterOptionsInput } from "src/api/graphql/graphql"
import { BatchDefinitionForValidationRunPopOver } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/BatchDefinitionForValidationRunPopOver/BatchDefinitionForValidationRunPopOver"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
import { Link } from "src/ui/typography/Text/Text"

export const VALIDATE_SUCCESS_TEXT = "Data Asset validation complete."
export const VALIDATE_ERROR_TEXT = "Unable to validate Data Asset."
export const SERVER_ERROR_TITLE = "Server Error"
export const VALIDATE_BUTTON_TEXT = "Validate"
export const UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION = (
  <>
    We were unable to validate your Asset. If the problem persists, contact <SupportEmailLink />
  </>
)
export const VALIDATE_ERROR_DESCRIPTION = (
  <>
    See{" "}
    <Link to="/logs" strong>
      logs
    </Link>{" "}
    for details.
  </>
)

export const CreateRunGXManagedCheckpointJobDcomument = graphql(`
  mutation CreateRunGXManagedCheckpointJob($input: CreateRunGxManagedCheckpointJobInput!) {
    createRunGxManagedCheckpointJob(input: $input) {
      jobId
    }
  }
`)

type RunValidationButtonProps = {
  dataAssetId: string
  hasBatchDefinition: boolean
}

export type ValidationStatus =
  | "inProgress"
  | "complete"
  | "error"
  | "inactive"
  | "agentNotConnected"
  | "setBatchDefinition"

export function RunValidationButton({ dataAssetId, hasBatchDefinition }: RunValidationButtonProps) {
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>("inactive")
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const [createJob, { data }] = useMutation(CreateRunGXManagedCheckpointJobDcomument)
  const jobId = data?.createRunGxManagedCheckpointJob?.jobId

  const runJob = (splitterOptions?: SplitterOptionsInput) => {
    setValidationStatus("inProgress")
    dataAssetId &&
      createJob({
        variables: { input: { dataAssetId, splitterOptions } },
        onError: (error: ApolloError) => {
          notificationApi.error({
            message: SERVER_ERROR_TITLE,
            description: UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION,
            duration: 0,
            placement: "top",
          })
          console.error(error.message)
          setValidationStatus("error")
        },
      })
  }

  const onValidateButtonClick = () => {
    if (validationStatus === "setBatchDefinition") return
    if (hasBatchDefinition && validationStatus !== "inProgress") return setValidationStatus("setBatchDefinition")
    runJob()
  }
  const job = useJobByID(jobId)

  /*
   * Handle validationStatus based on job status
   */
  useEffect(() => {
    if (job?.status) {
      switch (job?.status) {
        case "complete":
          notificationApi.success({
            message: VALIDATE_SUCCESS_TEXT,
            duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
            placement: "top",
          })
          setValidationStatus("complete")
          break
        case "error":
          if (job.jobError?.errorStackTrace?.includes("No Agent is connected")) {
            setValidationStatus("agentNotConnected")
          } else {
            notificationApi.error({
              message: VALIDATE_ERROR_TEXT,
              description: VALIDATE_ERROR_DESCRIPTION,
              duration: 0,
              placement: "top",
            })
            setValidationStatus("error")
          }
          break
        case "queued":
        case "inProgress":
          setValidationStatus("inProgress")
          break
        default:
          setValidationStatus("inactive")
      }
    }
  }, [job?.jobError?.errorStackTrace, job?.status, notificationApi])

  return (
    <>
      {notificationContextHolder}
      <AgentNotConnectedModal
        isVisible={validationStatus === "agentNotConnected"}
        setIsVisible={() => setValidationStatus("inactive")}
      />
      <BatchDefinitionForValidationRunPopOver
        validationStatus={validationStatus}
        onClose={() => setValidationStatus("inactive")}
        onSave={runJob}
      >
        <BifoldButton
          style={{ width: "150px" }}
          type="primary"
          icon={<CodeIcon />}
          onClick={onValidateButtonClick}
          loading={validationStatus === "inProgress"}
          menu={{
            items: [
              {
                label: "Generate Snippet",
                key: "snippet",
                onClick: () => {},
                disabled: true,
              },
            ],
          }}
        >
          <Icon name="play" size="14px" />
          {VALIDATE_BUTTON_TEXT}
        </BifoldButton>
      </BatchDefinitionForValidationRunPopOver>
    </>
  )
}
