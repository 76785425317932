import { InMemoryCache, InMemoryCacheConfig, defaultDataIdFromObject } from "@apollo/client"
import introspectionResult from "src/api/graphql/fragment-matcher-introspection-result"

export function createCache(config?: Partial<InMemoryCacheConfig>) {
  return new InMemoryCache({
    ...config,
    possibleTypes: {
      ...introspectionResult.possibleTypes,
    },
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "ExpectationSuite":
        case "ExpectationSuiteV2":
        case "DeleteExpectationSuiteV2":
          return `ExpectationSuiteV2:${responseObject.id}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
    typePolicies: {
      AssetRef: {
        fields: {
          jobs: {
            read(existing) {
              // since this is a local-only field, and its value is provided by a cache update that follows an unrelated query
              // we have to handle the case here that this field has not been populated yet
              return existing ?? []
            },
          },
        },
      },
      Checkpoint: {
        fields: {
          jobs: {
            read(existing) {
              // since this is a local-only field, and its value is provided by a cache update that follows an unrelated query
              // we have to handle the case here that this field has not been populated yet
              return existing ?? []
            },
          },
        },
      },
    },
  })
}
