const markersToTruncate = ["$v__", "$column_list", "$column_set"]

export const findParamListMarker =
  (findSimpleString?: boolean) => (evaluatedString: string, index: number, evaluatedStringArray: string[]) => {
    const markersPresent = markersToTruncate.find((marker) => evaluatedString.startsWith(marker))
    const isMarkerFound =
      Boolean(markersPresent) ||
      evaluatedString === " " ||
      evaluatedString === ", " ||
      (evaluatedString === "." && index === evaluatedStringArray.length - 1)
    if (findSimpleString) {
      return !isMarkerFound
    }
    return isMarkerFound
  }
