import { Table, Tag, Tooltip } from "antd"
import type { TableProps } from "antd"
import { useState } from "react"
import { capitalize, uniqBy } from "lodash-es"
import { intlFormatDistance } from "date-fns"

import { getDomainKwargs } from "src/common/utils/getExpectationGroupName"
import { graphql } from "src/api/graphql/gql"
import { ExpectationValidationResultV2, Domain, ExpectationsTable_ExpectationFragment } from "src/api/graphql/graphql"
import { EditExpectationFragment } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer"
import { getRenderer } from "src/Expectation/utils"
import { theme } from "src/ui/themes/theme"
import { Button } from "src/ui/Button/Button"
import { DeleteExpectationModal } from "src/DataAssets/AssetDetails/Expectations/DeleteExpectationModal"
import { FragmentType, unmaskFragment } from "src/api/graphql"

export const ExpectationsTable_ExpectationFragmentDocument = graphql(`
  fragment ExpectationsTable_Expectation on ExpectationConfiguration {
    geCloudId
    gxManaged
    kwargs
    expectationType
    domain {
      domainKwargs
      domainType
      id
      # columns {
      #   name
      #   order
      # }
    }
    renderedContent {
      ...RenderedExpectation
    }
    validationResults(input: { limit: 1 }) {
      runTime
      success
    }
  }
`)

type ExpectationTableExpectationFragment = FragmentType<typeof ExpectationsTable_ExpectationFragmentDocument>

interface ExpectationsTableProps {
  antdTableProps: TableProps
  assetId: string
  data: ExpectationTableExpectationFragment[]
  loading: boolean
  id: string
  setEditingExpectationFragment: (fragment: EditExpectationFragment) => void
  dataTestid?: string
}

type DataType = {
  expectation: ExpectationData
  lastValidated: Omit<ExpectationValidationResultV2, "__typename">
  actions: { expectationFragment: EditExpectationFragment; expectationId: string | null }
}

interface ExpectationData extends DomainData {
  content: string | JSX.Element | null
  id: string
}

type DomainData = {
  domainName: string
  sortValue: string
  truncateInfo: {
    truncate: boolean
    fullName: string
    restColumns?: string
  }
}

type OnChange = TableProps<DataType>["onChange"]
type SortOrder = "descend" | "ascend" | null

export const ExpectationsTable = (props: ExpectationsTableProps) => {
  const { assetId, data, id, loading, setEditingExpectationFragment, antdTableProps, dataTestid } = props

  const [domainSortOrder, setDomainSortOrder] = useState<SortOrder>(null)
  const unmaskedData = unmaskFragment(ExpectationsTable_ExpectationFragmentDocument, data)

  const handleChange: OnChange = () => {
    switch (domainSortOrder) {
      case null:
        setDomainSortOrder("ascend")
        break
      case "ascend":
        setDomainSortOrder("descend")
        break
      case "descend":
      default:
        setDomainSortOrder(null)
        break
    }
  }

  return (
    <Table
      {...antdTableProps}
      id={id}
      data-testid={dataTestid}
      columns={getColumns(unmaskedData, domainSortOrder, assetId ?? "", setEditingExpectationFragment)}
      dataSource={getExpectationRowData(unmaskedData, domainSortOrder)}
      loading={loading}
      onChange={handleChange}
      pagination={{ hideOnSinglePage: true, position: ["bottomRight"], pageSize: 15 }}
      rowKey={({ expectation }: DataType) => expectation.id}
    />
  )
}

const getColumns = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
  assetId: string,
  onEdit: (expectationFragment: EditExpectationFragment) => void,
): TableProps<DataType>["columns"] => [
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
    filters: getDomainFilter(data),
    onFilter: (value, record) => record.expectation.domainName === value,
    filterMultiple: true,
    sorter: (a, b) => sorter(a.expectation.sortValue, b.expectation.sortValue),
    sortOrder: domainSortOrder,
    render: (_, { expectation }) => expectation.content,
  },
  {
    title: "Last validated",
    dataIndex: "lastValidated",
    key: "lastValidated",
    filters: [
      { text: "Pass", value: true },
      { text: "Fail", value: false },
    ],
    onFilter: (value, record) => record.lastValidated.success === value,
    filterMultiple: false,
    width: "250px",
    render: (_, { lastValidated }) => {
      const { success, runTime } = lastValidated
      if (runTime === null && success === null) {
        return null
      }
      const color = success ? theme.colors.success.gxSuccess : theme.colors.error.gxError
      return (
        <span>
          <Tag color={color}>{success ? "Pass" : "Fail"}</Tag> {runTime}
        </span>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "100px",
    render: (_, { actions }) => {
      const { expectationId, expectationFragment } = actions
      return (
        <>
          <Button
            type="text"
            aria-label="Edit Expectation"
            icon="edit"
            disabled={!expectationFragment}
            onClick={() => (expectationFragment ? onEdit(expectationFragment) : undefined)}
          />
          <DeleteExpectationModal expectationId={expectationId} assetId={assetId} />
        </>
      )
    },
  },
]

const sorter = (a: string, b: string) => a.localeCompare(b, undefined, { numeric: true })
const noDomainPlaceholder = { domainName: "", sortValue: "", truncateInfo: { truncate: false, fullName: "" } }

const getExpectationRowData = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
): DataType[] => {
  if (!data) {
    return []
  }

  return data.map((exp) => {
    const { domainName, sortValue, truncateInfo } = exp?.domain
      ? getDomainData(exp.domain, domainSortOrder)
      : noDomainPlaceholder

    return {
      expectation: {
        content:
          getRenderer({ renderedValue: exp?.renderedContent?.[0], fallback: exp?.expectationType ?? undefined }) ??
          null,
        id: exp?.geCloudId ?? "",
        sortValue,
        domainName,
        truncateInfo,
      },
      lastValidated: {
        success: exp?.validationResults?.[0]?.success ?? null,
        runTime: exp?.validationResults?.[0]?.runTime ? fmtApprox(exp?.validationResults?.[0]?.runTime) : null,
      },
      actions: { expectationId: exp?.geCloudId ?? null, expectationFragment: exp as EditExpectationFragment },
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDomainData = (domain: Domain, domainSortOrder: SortOrder): DomainData => {
  // const MAXCOLNR = 4
  // const getMultiColSortValue = (columns?: Domain["columns"]) => {
  //   if (!columns || !domainSortOrder) {
  //     return ""
  //   }

  //   if (columns.length === 1) {
  //     return columns[0].name ?? ""
  //   }

  //   const sortingArray = [...columns]
  //   const ascSortedColumns = sortingArray.sort((a, b) => {
  //     return sorter(a.name ?? "", b.name ?? "")
  //   })

  //   const lastElement = ascSortedColumns[ascSortedColumns.length - 1]
  //   return domainSortOrder === "ascend" ? (ascSortedColumns[0].name ?? "") : (lastElement.name ?? "")
  // }
  const { column } = getDomainKwargs({ __typename: "ExpectationConfiguration", domain })
  const name = column ?? ""
  switch (domain.domainType) {
    case "COLUMN": {
      return {
        domainName: name,
        sortValue: name,
        truncateInfo: { truncate: false, fullName: name },
      }
    }
    case "COLUMN_PAIR":
    case "MULTICOLUMN":
    //   const shouldTruncate = (domain.columns?.length ?? 0) > MAXCOLNR
    //   const columnsCopy = domain.columns ? [...domain.columns] : []
    //   const showCols = shouldTruncate ? columnsCopy.splice(0, MAXCOLNR) : domain.columns
    //   const joinColNames = (cols: Domain["columns"] | undefined) => (cols ? cols.map((col) => col.name).join(", ") : "")
    //   return {
    //     domainName: joinColNames(showCols),
    //     sortValue: getMultiColSortValue(domain.columns),
    //     truncateInfo: {
    //       truncate: shouldTruncate,
    //       restColumns: joinColNames(columnsCopy),
    //       fullName: joinColNames(domain.columns),
    //     },
    //   }
    // }
    // eslint-disable-next-line no-fallthrough
    case "TABLE": {
      const name = "Asset"
      return { domainName: name, sortValue: name, truncateInfo: { truncate: false, fullName: name } }
    }
    default: {
      const name = "" // domain?.columns?.[0]?.name ?? domain.domainType
      return {
        domainName: name ?? "",
        sortValue: name ?? "",
        truncateInfo: { truncate: false, fullName: name ?? "" },
      }
    }
  }
}

const getDomainFilter = (data: ExpectationsTable_ExpectationFragment[]) => {
  if (!data) {
    return []
  }

  const domainCol = data.map((exp) => {
    return exp?.domain ? getDomainData(exp?.domain, "ascend") : noDomainPlaceholder
  })

  const sortedFilterList = domainCol.sort((a, b) => sorter(a.sortValue, b.sortValue))
  const uniqSortedFilterList = uniqBy(sortedFilterList, "truncateInfo.fullName")
  return uniqSortedFilterList.map(({ domainName, truncateInfo }) => ({
    text: <DomainFilterItem domainName={domainName} truncateInfo={truncateInfo} />,
    value: domainName,
  }))
}

function DomainFilterItem({ domainName, truncateInfo }: Omit<DomainData, "sortValue">) {
  return truncateInfo.truncate ? (
    <Tooltip placement="topRight" title={`...${truncateInfo.restColumns}`}>
      <span>{`${domainName}...`}</span>
    </Tooltip>
  ) : (
    <span>{domainName}</span>
  )
}

function fmtApprox(iso: string) {
  return capitalize(intlFormatDistance(new Date(iso), new Date()))
}
