import { MainContent } from "src/layout/MainContent"
import { PageHeader } from "src/ui/PageHeader"
import { HeaderTypes } from "src/ui/PageHeader/types"
import styled from "styled-components"
import { GetStarted } from "src/ui/GetStarted/GetStarted"
import { DataSourceChoice, NewAssetDrawer } from "src/DataAssets/connect-to-data/NewAssetDrawer"
import { NewSampleAssetDrawer } from "src/DataAssets/connect-to-data/sample-data/NewSampleAssetDrawer"

import { DataSourceCard, DataSourceCards } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { useState } from "react"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { ConnectToDataButton } from "src/DataAssets/connect-to-data/ConnectToDataButton"
import { Heading2, LG } from "src/ui/typography/Text/Text"
import { Col, Row } from "antd"
import { theme } from "src/ui/themes/theme"
const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

export function DataAssetsEmptyScreen({
  headerData,
  loading,
  isEditorOrAdmin,
}: {
  headerData: HeaderTypes
  loading: boolean
  isEditorOrAdmin: boolean
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSampleDataDrawerOpen, setIsSampleDataDrawerOpen] = useState(false)
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice>()
  const isSampleDataEnabled = useIsFeatureEnabled("sampleDataEnabled")

  const onDatasourceSelect = (choice: DataSourceChoice) => {
    setIsDrawerOpen(true)
    setDataSourceChoice(choice)
  }

  const isNewDataAssetEmptyStateEnabled = useIsFeatureEnabled("dataAssetEmptyStateEnabled")
  const emptyStateTitle = isNewDataAssetEmptyStateEnabled
    ? "Connect to a data source to get started"
    : "Get started by adding a data asset."

  const showDataAssetCards = isEditorOrAdmin && isNewDataAssetEmptyStateEnabled
  const showConnectToDataButton = isEditorOrAdmin && !isNewDataAssetEmptyStateEnabled
  return (
    <PageHeader headerContent={headerData} loading={loading}>
      <MainContent>
        <CenteredContainer>
          <GetStarted large title={emptyStateTitle}>
            {showConnectToDataButton && <ConnectToDataButton />}
            {showDataAssetCards && (
              <>
                <DataSourceCards large setChoice={onDatasourceSelect} />

                {isSampleDataEnabled && (
                  <>
                    <Heading2 style={{ marginTop: theme.spacing.vertical.s }}>
                      Not ready to connect to your data?
                    </Heading2>
                    <Row gutter={16}>
                      <Col span={8}>
                        <DataSourceCard
                          large
                          type="POSTGRES"
                          onClick={() => setIsSampleDataDrawerOpen(true)}
                          isSampleDataSource
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <NewAssetDrawer
                  dataSourceChoiceFromEmptyState={dataSourceChoice}
                  isOpen={isDrawerOpen}
                  setIsOpen={setIsDrawerOpen}
                />
                {isSampleDataEnabled && (
                  <NewSampleAssetDrawer isOpen={isSampleDataDrawerOpen} setIsOpen={setIsSampleDataDrawerOpen} />
                )}
              </>
            )}
            {!isEditorOrAdmin && <LG>Only Editors and Admins can add Data Assets.</LG>}
          </GetStarted>
        </CenteredContainer>
      </MainContent>
    </PageHeader>
  )
}
