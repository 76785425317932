import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { getMetaNotes } from "src/Expectation/utils.tsx"

export const MapExpectationConfigToJsonConfig_ExpectationFragmentDocument = graphql(`
  fragment MapExpectationConfigToJsonConfig_Expectation on ExpectationConfiguration {
    kwargs
    renderedContent {
      value {
        ... on GraphType {
          metaNotes
        }
        ... on StringValueType {
          metaNotes
        }
        ... on TableType {
          metaNotes
        }
      }
    }
  }
`)

export function mapExpectationConfigToJsonConfig(
  maskedExpectation: FragmentType<typeof MapExpectationConfigToJsonConfig_ExpectationFragmentDocument>,
) {
  const expectation = unmaskFragment(MapExpectationConfigToJsonConfig_ExpectationFragmentDocument, maskedExpectation)
  const renderedContent = expectation?.renderedContent?.[0]
  const metaNotes = renderedContent ? getMetaNotes(renderedContent) : null
  return {
    ...JSON.parse(expectation?.kwargs ?? "{}"),
    meta: {
      notes: metaNotes ?? "",
    },
  }
}
