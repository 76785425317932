import { ExpectationConfig, RowCondition } from "src/Expectation/CreateExpectationDrawer/types"
import {
  RowConditionOperator,
  RowConditionStringOperator,
  ROW_CONDITION_STRING_OPERATORS,
} from "src/Expectation/uiForms/customRenderers/RowConditionControl/useColumnTypeOperators"

export const parseRowConditionStringToObject = (rowCondition: string): RowCondition | undefined => {
  if (!rowCondition) {
    return undefined
  }

  const column =
    rowCondition.split('col("').length > 1 ? (rowCondition.split('col("').pop()?.split('")')[0].trim() ?? "") : ""
  if (column === "") {
    return undefined
  }

  const operatorMatches = [] as Array<RowConditionStringOperator>
  for (const operator of ROW_CONDITION_STRING_OPERATORS) {
    if (rowCondition.includes(operator)) {
      operatorMatches.push(operator)
    }
  }
  // Sort matches by length in descending order and choose longest match
  const stringOperator = operatorMatches.sort((a, b) => b.length - a.length)[0]

  if (!stringOperator) {
    return undefined
  }

  if (stringOperator === ".notNull()") {
    return { column: column, operator: "is not null" }
  }

  const stringParameter = rowCondition.split(stringOperator)[1].trim()
  let parameter
  let operator = stringOperator as RowConditionOperator
  if (stringParameter.includes('date("')) {
    parameter = stringParameter.split('date("').pop()?.split('")')[0].trim() ?? ""
    switch (stringOperator) {
      case "!=":
        operator = "is not on"
        break
      case "<":
        operator = "is before"
        break
      case "<=":
        operator = "is before or on"
        break
      case "==":
        operator = "is on"
        break
      case ">":
        operator = "is after"
        break
      case ">=":
        operator = "is after or on"
        break
    }
  } else if (stringParameter.includes('"')) {
    parameter = stringParameter.split('"')[1]
    switch (stringOperator) {
      case "!=":
        operator = "is not"
        break
      case "==":
        operator = "is"
        break
      case "is":
        operator = "is"
        break
    }
  } else {
    parameter = Number(stringParameter)
  }

  return { column: column, operator: operator, parameter: parameter }
}

export const parseRowConditionObjectToString = ({ column, operator, parameter }: RowCondition): string => {
  if (!column || !operator) {
    return ""
  }

  const parsedColumn = `col("${column}")`

  let parsedOperator = ""
  let isDate = false
  switch (operator) {
    case "is":
      parsedOperator = "=="
      break
    case "is not":
      parsedOperator = "!="
      break
    case "is not null":
      parsedOperator = ".notNull()"
      break
    case "is on":
      parsedOperator = "=="
      isDate = true
      break
    case "is not on":
      parsedOperator = "!="
      isDate = true
      break
    case "is before":
      parsedOperator = "<"
      isDate = true
      break
    case "is before or on":
      parsedOperator = "<="
      isDate = true
      break
    case "is after":
      parsedOperator = ">"
      isDate = true
      break
    case "is after or on":
      parsedOperator = ">="
      isDate = true
      break
    case "=":
      parsedOperator = "=="
      break
    default:
      parsedOperator = operator.toString()
  }

  let parsedParameter = ""
  switch (typeof parameter) {
    case "string":
      parsedParameter = `"${parameter}"`
      break
    default:
      parsedParameter = parameter ? parameter.toString() : ""
  }

  if (isDate) {
    parsedParameter = `date(${parsedParameter})`
  }

  return `${parsedColumn}${parsedOperator}${parsedParameter}`
}

export const handleRowConditionPayload = (config: ExpectationConfig): Record<string, unknown> => {
  const payload = config as Record<string, unknown>
  if ("row_condition" in config && config.row_condition) {
    let rowCondition
    if (typeof config.row_condition === "string") {
      rowCondition = parseRowConditionStringToObject(config.row_condition)
    } else {
      rowCondition = parseRowConditionObjectToString(config.row_condition)
    }

    if (rowCondition) {
      payload.row_condition = rowCondition
      payload.condition_parser = "great_expectations"
    } else {
      // if the parsed rowCondition is empty string or undefined
      // remove row condition props from the config
      delete payload.row_condition
      delete payload.condition_parser
    }
  }
  return payload
}
