/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Drawer } from "src/ui/Drawer/Drawer"
import { Heading2 } from "src/ui/typography/Text/Text"
import { AddSampleDataSourceWithAssets } from "src/DataAssets/connect-to-data/sample-data/AddSampleDataSourceWithAssets"
import { DataSourceCrudDrawerStyles } from "src/DataAssets/connect-to-data/NewAssetDrawer"

interface NewSampleAssetDrawerProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const title = "Add a Data Asset"

export function NewSampleAssetDrawer({ isOpen, setIsOpen }: NewSampleAssetDrawerProps) {
  return (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={<Heading2>{title}</Heading2>}
    >
      <AddSampleDataSourceWithAssets onFinish={() => setIsOpen(false)} />
    </Drawer>
  )
}
