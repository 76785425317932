import { Form, message, Spin, Flex, AlertProps } from "antd"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { useCallback, useEffect, useState, useMemo } from "react"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { ApolloCache, ApolloError, FetchResult, useMutation, useQuery } from "@apollo/client"
import { findIndex, xor } from "lodash-es"
import { getAddAssetButtonText } from "src/DataAssets/connect-to-data/utils"
import {
  AddAssetsToDataSourceDocument,
  AddAssetsToDataSourceMutation,
  DatasourceWithRunsFragment,
  DatasourcesWithRunsDocument,
  DatasourcesWithRunsQuery,
} from "src/api/graphql/graphql-operations"
import { DatasourceTypeV2 } from "src/api/graphql/graphql"
import { AddAssetDrawerHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useAssetCreationJobStatus } from "src/DataAssets/connect-to-data/useAssetCreationJobStatus"
import { SelectTableNames } from "src/DataAssets/connect-to-data/select-table-names/SelectTableNames"
import { LoadingOutlined } from "@ant-design/icons"
import { theme } from "src/ui/themes/theme"
import { graphql } from "src/api/graphql/gql"
import { getMatchingAssets } from "src/DataAssets/connect-to-data/select-table-names/tableNameUtils"
import { getAddressFromConfig } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"
import { useAnalytics } from "src/analytics/useAnalytics"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
export const CreateListTableNamesJobDocument = graphql(`
  mutation CreateListTableNamesJob($datasourceId: UUID!) {
    createListTableNamesJob(datasourceId: $datasourceId) {
      jobId
    }
  }
`)

export const GetDatasourceWithConfigDocument = graphql(`
  query GetDatasourceWithConfig($id: UUID!) {
    datasourceV2(id: $id) {
      id
      type
      config
    }
  }
`)

export type ListTableNamesMutationErrorProps = Partial<AlertProps>
type Props = {
  onBack: () => void
  onFinish: () => void
  dataSource: DatasourceWithRunsFragment
  dataSourceType: SupportedDataSource
}

export function AddAssetToExistingDataSource({ dataSource, onBack, onFinish, dataSourceType }: Props) {
  const [assetCreationError, setAssetCreationError] = useState<null | AlertProps>(null)
  const [form] = Form.useForm()
  const [jobIsPending, setJobIsPending] = useState<boolean>(false)
  const [jobErrorMessage, setJobErrorMessage] = useState<null | AlertProps>(null)
  const [tableNames, setTableNames] = useState<string[]>()
  const [selectedTableNames, setSelectedTableNames] = useState<string[]>([])
  const analytics = useAnalytics()
  /**
   ** Get agent status to show error modal if agent is not active
   */
  const { executeFunction: loadAgentStatus } = useLazyAgentStatus()
  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  const [createJob, { data: listTableNamesData, reset: resetCreateJobMutation }] = useMutation(
    CreateListTableNamesJobDocument,
    {
      onError: (err: ApolloError) => handleListTableNamesError({ description: err.message, message: err.name }),
      variables: {
        datasourceId: dataSource.id,
      },
    },
  )
  const isGXAgentEnabled = useIsGXAgentEnabled()

  const getTableNames = useCallback(async () => {
    // Check if agent is connected before running job if using an agent, otherwise create job if using the runner

    const runJobWithRunner = async () => {
      setJobIsPending(true)
      setJobErrorMessage(null)
      await createJob()
    }

    const runJobWithAgent = async () => {
      const res = await loadAgentStatus()
      const isAgentConnected = res.data?.agentStatus.active
      if (isAgentConnected) {
        setJobIsPending(true)
        setJobErrorMessage(null)
        await createJob()
      } else {
        setIsAgentErrorModalVisible(true)
      }
    }
    const runJobFn = isGXAgentEnabled ? runJobWithAgent : runJobWithRunner
    runJobFn()
  }, [createJob, isGXAgentEnabled, loadAgentStatus])

  useEffect(() => {
    getTableNames()
  }, [getTableNames])

  const handleListTableNamesError: ({ description, message, type }: ListTableNamesMutationErrorProps) => void =
    useCallback(
      ({ description, message, type }) => {
        setJobErrorMessage({ description, message: message ?? null, type: type ?? "error" })
        setJobIsPending(false)
        resetCreateJobMutation()
      },
      [resetCreateJobMutation],
    )

  useAssetCreationJobStatus({
    jobId: jobIsPending ? listTableNamesData?.createListTableNamesJob?.jobId : undefined,
    onError: handleListTableNamesError,
    onComplete: (job) => {
      if (job?.tableNames?.length && job.tableNames.length > 0) {
        setTableNames(job.tableNames as string[]) // we need to fix this on the server
      }
      setJobIsPending(false)
      resetCreateJobMutation()
    },
  })

  const [addDataAssets, { reset: resetSaveDatasourceMutation, loading }] = useMutation(AddAssetsToDataSourceDocument, {
    update: updateCache,
    onError: (error: ApolloError) => {
      setAssetCreationError({ message: error.message, type: "error" })
    },
    onCompleted: (result) => {
      // reset mutations?
      const assets = result.addDataAssetsToDataSource?.assets
      message.success(
        assets?.length === 1 ? `1 Data Asset added` : `${assets?.length} Data Assets added`,
        MESSAGE_DURATION_SECONDS,
      )
      onClose()
      onFinish()
    },
  })
  const onClose = useCallback(() => {
    resetSaveDatasourceMutation()
    form.resetFields()
    onBack()
  }, [form, onBack, resetSaveDatasourceMutation])

  const newSelectedTableLength = useMemo(() => {
    // exclude table names that are already part of the asset
    const matchingTableNames = tableNames ? getMatchingAssets(tableNames, dataSource.assets) : []

    return selectedTableNames?.length - matchingTableNames.length
  }, [selectedTableNames?.length, dataSource.assets, tableNames])

  useEffect(() => {
    if (newSelectedTableLength) {
      setAssetCreationError(null)
    }
  }, [newSelectedTableLength])

  const onSubmit = useCallback(async () => {
    if (!newSelectedTableLength) {
      setAssetCreationError({ message: "Select at least 1 table to add as Asset", type: "warning" })
      return
    }

    // exclude table names that are already part of the asset
    const matchingTableNames = tableNames ? getMatchingAssets(tableNames, dataSource.assets) : []
    const tableNamesToAdd = selectedTableNames?.filter((tableName) => !matchingTableNames?.includes(tableName))

    analytics?.capture("add_asset.existing_datasource.submit", {
      tableNames: tableNamesToAdd,
      selectAll: selectedTableNames.length === tableNames?.length,
    })

    return addDataAssets({
      variables: {
        input: {
          dataSourceId: dataSource.id,
          tableNames: tableNamesToAdd,
        },
      },
    })
  }, [
    addDataAssets,
    dataSource.id,
    dataSource.assets,
    newSelectedTableLength,
    tableNames,
    selectedTableNames,
    analytics,
  ])

  interface DataSourceWithConfigState {
    dataSourceType?: DatasourceTypeV2
    config?: string
  }
  const [dsWithConfig, setDsWithConfig] = useState<DataSourceWithConfigState>({
    dataSourceType: undefined,
    config: undefined,
  })
  const { loading: configLoading } = useQuery(GetDatasourceWithConfigDocument, {
    variables: {
      id: dataSource.id,
    },
    onCompleted: (dsData) => {
      if (!configLoading) {
        setDsWithConfig({ dataSourceType: dsData.datasourceV2?.type, config: dsData?.datasourceV2?.config })
      }
    },
  })

  const dataSourceAddress = getAddressFromConfig(dataSource.name, dsWithConfig.config, dsWithConfig?.dataSourceType)
  const addAssetButtonText = getAddAssetButtonText(true, jobIsPending, newSelectedTableLength)
  const matchingTableNames = getMatchingAssets(tableNames ?? [], dataSource.assets)
  const isAddAssetBtnDisabled = xor(matchingTableNames, tableNames).length === 0

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        <AddAssetDrawerHeader
          dataSourceType={dataSourceType}
          dataSourceName={dataSource.name}
          dataSourceAddress={dataSourceAddress}
        />
        {jobIsPending && (
          <Flex gap="small" align="center">
            <Spin
              indicator={
                <LoadingOutlined
                  spin
                  style={{
                    fontSize: theme.typography.fontSize.large,
                    color: theme.colors.primaryColors.gxAccentMedium,
                  }}
                />
              }
            />
            <p>Loading tables</p>
          </Flex>
        )}
        {!jobIsPending && (
          <>
            {tableNames && (
              <SelectTableNames
                tableNames={tableNames}
                setSelectedTableNames={setSelectedTableNames}
                matchingTableNames={matchingTableNames}
              />
            )}
            {jobErrorMessage && (
              <AlertBanner
                message={jobErrorMessage.message ?? "There was an error connecting to Data Source."}
                description={jobErrorMessage.description}
                type={jobErrorMessage.type}
              />
            )}
            {assetCreationError && (
              <AlertBanner
                message={assetCreationError.message ?? "There was an error adding Data Source."}
                description={assetCreationError.description}
                type={assetCreationError.type}
              />
            )}
          </>
        )}
      </ScrollableFlex>
      <Drawer.Footer>
        <Drawer.FooterButton
          onClick={() => {
            return onClose()
          }}
        >
          Back
        </Drawer.FooterButton>
        <Drawer.FooterButton
          type="primary"
          loading={loading || jobIsPending}
          disabled={isAddAssetBtnDisabled}
          onClick={() => {
            return onSubmit()
          }}
        >
          {addAssetButtonText}
        </Drawer.FooterButton>
      </Drawer.Footer>
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
    </>
  )
}

function updateCache(cache: ApolloCache<unknown>, result: FetchResult<AddAssetsToDataSourceMutation>) {
  const dataAssets = result.data?.addDataAssetsToDataSource?.assets
  cache.updateQuery({ query: DatasourcesWithRunsDocument }, (cachedQuery: DatasourcesWithRunsQuery | null) => {
    if (!dataAssets || !cachedQuery) {
      return undefined
    }
    const indexOfDataSource = findIndex(cachedQuery.datasourcesV2, ({ id }) => id === dataAssets[0]?.datasourceId)
    if (!(indexOfDataSource >= 0)) {
      return undefined
    }
    const updatedDataSource = cachedQuery.datasourcesV2[indexOfDataSource]
    return {
      ...cachedQuery,
      datasourcesV2: [
        ...cachedQuery.datasourcesV2.slice(0, indexOfDataSource),
        { ...updatedDataSource, assets: [...updatedDataSource.assets, ...dataAssets] },
        ...cachedQuery.datasourcesV2.slice(indexOfDataSource + 1),
      ],
    }
  })
}
