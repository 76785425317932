import { useState, useCallback } from "react"
import { useMutation } from "@apollo/client"
import { Flex, Form, Popover } from "antd"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon"
import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { frequencyOptions, startTimeOptions } from "src/DataAssets/AssetDetails/Expectations/ExpectationSuiteDrawer"
import { Select } from "src/ui/Select"
import { useTheme } from "styled-components"
import {
  getNextHour,
  getHourlyIntervalFromCron,
  DEFAULT_SCHEDULE_FREQUENCY,
  generateCronWithCyclicHours,
} from "src/common/utils/cron"
import { Heading3 } from "src/ui/typography/Text/Text"

export const EditScheduleMutationDocument = graphql(`
  mutation EditSchedule($input: UpdateScheduleInput!) {
    updateScheduleV2(input: $input) {
      schedule {
        schedule
        startTime
        id
        isEnabled
      }
    }
  }
`)

export function EditSchedulePopover({
  schedule,
}: {
  schedule: FragmentType<typeof EditScheduleForm_ScheduleFragmentDocument> | undefined | null
}) {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  return (
    <Popover
      overlayInnerStyle={{ padding: theme.spacing.vertical.s }}
      placement="bottomLeft"
      arrow={false}
      destroyTooltipOnHide
      content={<EditScheduleForm schedule={schedule} onClose={() => setIsOpen(false)} />}
      title={
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Icon
              name="schedule"
              height={theme.spacing.scale.xs}
              width={theme.spacing.scale.xs}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: theme.spacing.horizontal.xxs,
              }}
            />
            <Heading3>Validation schedule</Heading3>
          </Flex>
          <Button icon="close" iconSize="18px" type="text" onClick={() => setIsOpen(false)} />
        </Flex>
      }
      trigger="click"
      open={isOpen}
      onOpenChange={() => setIsOpen(true)}
    >
      <Button type="text" aria-label="Edit Schedule" icon="edit" onClick={() => setIsOpen(!isOpen)} />
    </Popover>
  )
}

const EditScheduleForm_ScheduleFragmentDocument = graphql(`
  fragment EditScheduleForm_Schedule on Schedule {
    id
    schedule
    startTime
  }
`)

type ScheduleForm = { scheduledStart: number; scheduledFrequency: number }

function EditScheduleForm({
  schedule: maskedSchedule,
  onClose,
}: {
  schedule: FragmentType<typeof EditScheduleForm_ScheduleFragmentDocument> | undefined | null
  onClose: () => void
}) {
  const theme = useTheme()
  const schedule = unmaskFragment(EditScheduleForm_ScheduleFragmentDocument, maskedSchedule)
  const [editScheduleMutation, { loading, error }] = useMutation(EditScheduleMutationDocument)
  const nextHour =
    schedule?.startTime ?? startTimeOptions.find((v) => v.value === getNextHour(new Date().getHours()))?.value ?? 0
  const selectedFrequencyOption = schedule?.schedule
    ? getHourlyIntervalFromCron(schedule.schedule)
    : DEFAULT_SCHEDULE_FREQUENCY

  const onFinish = useCallback(
    async (fields: ScheduleForm) => {
      if (!schedule?.id) {
        return // not a possibility; just assuring the compiler here
      }
      await editScheduleMutation({
        variables: {
          input: {
            id: schedule?.id,
            schedule: generateCronWithCyclicHours({ start: fields.scheduledStart, freq: fields.scheduledFrequency }),
            startTime: fields.scheduledStart,
          },
        },
      })
      onClose()
    },
    [editScheduleMutation, onClose, schedule?.id],
  )
  return (
    <Form<ScheduleForm> layout="vertical" style={{ width: "300px" }} onFinish={onFinish}>
      <Form.Item initialValue={selectedFrequencyOption} name="scheduledFrequency" label="Frequency" required>
        <Select options={frequencyOptions} defaultValue={frequencyOptions[frequencyOptions.length - 1]} />
      </Form.Item>
      <Form.Item initialValue={nextHour} name="scheduledStart" label="Start time (local timezone)" required>
        <Select options={startTimeOptions} />
      </Form.Item>
      <Form.Item
        style={{ marginTop: theme.spacing.vertical.s }}
        {...(error && { validateStatus: "error", help: error.message })}
      >
        <Flex justify="flex-end">
          <Button type="primary" loading={loading} htmlType="submit">
            Save
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  )
}
