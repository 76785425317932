import { useMutation } from "@apollo/client"
import { ButtonProps, message, notification } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Button } from "src/ui/Button/Button"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { JobStateObserver } from "src/common/components/JobStateObserver"
import {
  FETCH_METRICS_ERROR_DESCRIPTION,
  FETCH_METRICS_ERROR_TEXT,
  FETCH_METRICS_SUCCESS_TEXT,
} from "src/DataAssets/words"
import { MESSAGE_DURATION_SECONDS, NOTIFICATION_INFINITE_DURATION_SECONDS } from "src/common/config"
import { MetricType } from "src/api/graphql/graphql"
import { graphql } from "src/api/graphql/gql"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"

type Props = {
  dataAssetId: string
  size?: ButtonProps["size"]
  label?: string
  loadingLabel?: string
  icon?: "refresh" | "analytics"
  type?: "default" | "primary"
  children?: ButtonProps["children"]
  disabled?: boolean
  fetchMetricsOnLoad?: boolean
}

export const CreateMetricsListJobDocument = graphql(`
  mutation createMetricsListJob($dataAssetId: UUID!, $metricList: [MetricType]!) {
    createMetricsListJob(dataAssetId: $dataAssetId, metricList: $metricList) {
      jobId
    }
  }
`)

export function GenerateColumnDescriptiveMetricsButton({
  dataAssetId,
  size = "middle",
  label = "Refresh",
  loadingLabel = "Refreshing...",
  icon = "refresh",
  type = "default",
  disabled = false,
  fetchMetricsOnLoad = false,
  children,
}: Props) {
  const [createMetricListJobMutation] = useMutation(CreateMetricsListJobDocument, {})
  const [jobId, setJobId] = useState<string | null>(null)
  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)
  const [isLoadingCurrentJobRequest, setIsLoadingCurrentJobRequest] = useState(false)
  const [preloadMetrics, setPreloadMetrics] = useState(fetchMetricsOnLoad)
  const { executeFunction: loadAgentStatus, loading: isLoadingAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()
  const text = isLoadingCurrentJobRequest ? loadingLabel : label
  const [notifApi, notifContextHolder] = notification.useNotification()

  //Preloading data only loads some metric types
  const preloadDataMetricTypes: MetricType[] = useMemo(() => ["TABLE_COLUMNS", "TABLE_COLUMN_TYPES"], [])

  const allMetricTypes: MetricType[] = [
    ...preloadDataMetricTypes,
    "TABLE_ROW_COUNT",
    "COLUMN_MAX",
    "COLUMN_MIN",
    "COLUMN_MEAN",
    "COLUMN_MEDIAN",
    "COLUMN_NULL_COUNT",
  ]

  const reset = useCallback(() => {
    // Reset states when job is finished
    setIsLoadingCurrentJobRequest(false)
    setJobId(null)
  }, [])

  const onComplete = () => {
    message.success(FETCH_METRICS_SUCCESS_TEXT, MESSAGE_DURATION_SECONDS)
    reset()
  }

  const onError = () => {
    notifApi.error({
      message: FETCH_METRICS_ERROR_TEXT,
      description: FETCH_METRICS_ERROR_DESCRIPTION,
      placement: "top",
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
    })
    reset()
  }

  const createMetricListJob = useCallback(
    (metricList: MetricType[]) => {
      return createMetricListJobMutation({
        variables: {
          dataAssetId: dataAssetId,
          metricList: metricList,
        },
        onCompleted: (data) => {
          setJobId(data?.createMetricsListJob?.jobId ?? null)
        },
        onError: () => {
          reset()
        },
      })
    },
    [createMetricListJobMutation, dataAssetId, reset],
  )

  const tryToRunJob = useCallback(
    async (metricList: MetricType[]) => {
      const runJobWithAgent = async (metricList: MetricType[]) => {
        const res = await loadAgentStatus()
        const isAgentConnected = res.data?.agentStatus.active
        if (isAgentConnected) {
          createMetricListJob(metricList)
          setIsLoadingCurrentJobRequest(true)
        } else {
          // Note: The modal is opened programatically
          //       but always closed manually
          setIsAgentErrorModalVisible(true)
          reset()
        }
      }
      const runJobWithRunner = (metricList: MetricType[]) => {
        createMetricListJob(metricList)
        setIsLoadingCurrentJobRequest(true)
      }

      const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
      runJobFn(metricList)
    },
    [loadAgentStatus, reset, createMetricListJob, agentEnabled],
  )

  useEffect(() => {
    if (preloadMetrics) {
      tryToRunJob(preloadDataMetricTypes)
      setPreloadMetrics(false)
    }
  }, [preloadDataMetricTypes, preloadMetrics, tryToRunJob])

  return (
    <>
      <Button
        type={type}
        onClick={() => {
          tryToRunJob(allMetricTypes)
        }}
        size={size}
        icon={icon}
        loading={isLoadingCurrentJobRequest || isLoadingAgentStatus}
        disabled={isLoadingCurrentJobRequest || isLoadingAgentStatus || disabled}
      >
        {children ?? text}
      </Button>
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
      {jobId && <JobStateObserver jobId={jobId} onComplete={onComplete} onError={onError} />}
      {notifContextHolder}
    </>
  )
}
