import { ReactNode, useState } from "react"

import { SplitterDateConfig } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { Flex, Popover, Radio, Space } from "antd"
import { BodyNormal, Heading3 } from "src/ui/typography/Text/Text"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"
import { RadioGroup } from "src/ui/Radio/RadioGroup"
import { ValidationStatus } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/RunValidationButton"
import { Button } from "src/ui/Button/Button"
import styled from "styled-components"
import { BatchDefinitionForValidationRunForm } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/BatchDefinitionForValidationRunPopOver/BatchDefinitionForValidationRunForm"

export const VALIDATE_DATA_ASSET_TITLE = "Validate Data Asset"

const TextTitleContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.vertical.s};
  margin-bottom: ${({ theme }) => theme.spacing.vertical.xs};
`

const ContentWrapper = styled.div`
  width: 450px;
  padding-bottom: 8px;
`

export type SplitterOptions = Record<string, SplitterDateConfig>

type BatchDefinitionForValidationRunPopOverProps = {
  children: ReactNode
  validationStatus: ValidationStatus
  onClose: () => void
  onSave: (splitterOptions?: SplitterOptions) => void
}

export const BatchDefinitionForValidationRunPopOver = ({
  children,
  validationStatus,
  onClose,
  onSave,
}: BatchDefinitionForValidationRunPopOverProps) => {
  const [selectedBatchType, setSelectedBatchType] = useState<"latest" | "custom">("latest")
  const { isSplitterSupported, ...splitterData } = useGetSplitterData({
    isVisible: validationStatus === "setBatchDefinition",
  })

  const onReset = () => {
    setSelectedBatchType("latest")
  }

  return (
    <Popover
      placement="bottom"
      arrow={false}
      destroyTooltipOnHide
      title={
        <Flex align="center" justify="space-between">
          <Heading3>{VALIDATE_DATA_ASSET_TITLE}</Heading3>
          <Button
            icon="close"
            type="text"
            aria-label="Close Batch definition window"
            onClick={() => {
              onClose()
              onReset()
            }}
          />
        </Flex>
      }
      content={
        <ContentWrapper>
          <BatchDefinitionDescription column={2} />
          <TextTitleContainer>
            <BodyNormal>Specify a single Batch to validate:</BodyNormal>
          </TextTitleContainer>
          <RadioGroup
            value={selectedBatchType}
            onChange={(e) => {
              setSelectedBatchType(e.target.value)
            }}
          >
            <Space direction="vertical">
              <Radio key="latest" value="latest">
                Latest Batch
              </Radio>
              <Radio disabled={!isSplitterSupported} key="custom" value="custom">
                Custom Batch
              </Radio>
            </Space>
          </RadioGroup>
          <BatchDefinitionForValidationRunForm
            onFinish={(values?: SplitterOptions) => {
              onSave(values)
              onReset()
            }}
            splitterData={splitterData}
            isCustomBatchType={selectedBatchType === "custom"}
          />
        </ContentWrapper>
      }
      trigger="click"
      open={validationStatus === "setBatchDefinition"}
    >
      {children}
    </Popover>
  )
}
