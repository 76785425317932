import { Flex } from "antd"

import { useRequireRole } from "src/common/hooks/useRequireRole"
import { Param } from "src/ui/Param/Param"
import { getUIBatchDef } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { FragmentType, graphql, unmaskFragment } from "src/api/graphql"
import { theme } from "src/ui/themes/theme"
import { EditBatchPopover } from "src/DataAssets/AssetDetails/Expectations/EditBatchPopover"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { Image } from "src/ui/Image"

type BatchDefinitionProps = {
  dataAssetId: string
  splitterDataFragment?: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument> | null
}

export const BatchDefinitionDescription_SplitterFragmentDocument = graphql(`
  fragment BatchDefinitionDescription_Splitter on SplitterUnion {
    ... on SplitterYearAndMonthAndDay {
      columnName
    }
    ... on SplitterYearAndMonth {
      columnName
    }
    ... on SplitterYear {
      columnName
    }
    ... on SplitterColumnValue {
      columnName
    }
    ... on SplitterConvertedDateTime {
      columnName
    }
    ... on SplitterDatetimePart {
      columnName
    }
    ... on SplitterDividedInteger {
      columnName
    }
    ... on SplitterHashedColumn {
      columnName
    }
    ... on SplitterModInteger {
      columnName
    }
    ... on SplitterMultiColumnValue {
      columnNames
    }
  }
`)

export const BatchDefinitionWithEditing: React.FC<BatchDefinitionProps> = ({ dataAssetId, splitterDataFragment }) => {
  const isEditorRole = useRequireRole("EDITOR")
  const { splitter, splitterColumns } = getSplitterData(splitterDataFragment)

  return (
    <Flex justify="space-between" style={{ width: "100%" }}>
      <Flex gap={theme.spacing.horizontal.xxs} align="center" style={{ marginTop: theme.spacing.vertical.xxs }}>
        <Image aria-label="batch" type="batch" />
        {splitter ? (
          <BodyNormal>
            Validate by {splitter.toLowerCase()} on column <Param>{splitterColumns}</Param>
          </BodyNormal>
        ) : (
          <BodyNormal>Validate entire Asset</BodyNormal>
        )}
      </Flex>
      {isEditorRole && (
        <Flex align="center">
          <EditBatchPopover dataAssetId={dataAssetId} splitter={splitter} />
        </Flex>
      )}
    </Flex>
  )
}

export function getSplitterData(
  splitterData?: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument> | null,
) {
  if (!splitterData) {
    return {
      splitter: undefined,
      splitterColumns: undefined,
    }
  }

  const splitterUnmasked = unmaskFragment(BatchDefinitionDescription_SplitterFragmentDocument, splitterData)
  const splitterType = splitterUnmasked?.["__typename"]
  const splitter = getUIBatchDef(splitterType)
  const isMulticolumnSplitter = splitterUnmasked?.["__typename"] === "SplitterMultiColumnValue"
  const splitterColumns = isMulticolumnSplitter
    ? splitterUnmasked?.columnNames.join(", ")
    : splitterUnmasked?.columnName

  return {
    splitter,
    splitterColumns,
  }
}
