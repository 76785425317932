import { useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Col, Row, Card, Descriptions, Space, Alert } from "antd"

import { graphql } from "src/api/graphql/gql"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MetricsTable } from "src/DataAssets/AssetDetails/MetricsTable"
import { SimpleNewExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer"
import { useAssetSummaryDescriptionItems } from "src/DataAssets/AssetDetails/useAssetSummaryDescriptionItems"
import { CreateExpectationForAssetDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetDrawer"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { MainContent } from "src/layout/MainContent"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { BodyStrong, Heading3 } from "src/ui/typography/Text/Text"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { useIsSampleData } from "src/common/hooks/useIsSampleData.ts"
import { SAMPLE_DATA_OVERVIEW_TAB_MESSAGE } from "src/DataAssets/AssetDetails/Expectations/words.tsx"

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  margin-right: 2px;
  display: flex;
`

const StyledIconTextContainer = styled(BodyStrong)`
  display: inline-flex;
  align-items: center;
`

export const OverviewTab_DataAssetDocument = graphql(`
  query OverviewTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
      }
      ...AssetSummaryDescriptionItems_DataAsset
    }
  }
`)

export function OverviewTab() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const dataAssetId = decodeURIComponent(assetId ?? "")

  const { data } = useQuery(OverviewTab_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })

  const isEditor = useRequireRole("EDITOR")
  const isSujeEnabled = useIsFeatureEnabled("sujeEnabled")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isAssistantRequested, setIsAssistantRequested] = useState(false)

  const doRequestAssistant = () => {
    setIsAssistantRequested(true)
    // Wait for 30 seconds (30,000 milliseconds) before setting it to false
    setTimeout(() => {
      setIsAssistantRequested(false)
    }, 30000)
  }

  const rdAssistantEnabled = useIsFeatureEnabled("rdAssistantEnabled")

  const isSampleData = useIsSampleData()

  const headerContent: HeaderTypes = {
    title: data?.dataAsset?.name ?? "",
    rootPath: "data-assets",
    navigateBackTo: "/data-assets",
    ...(isEditor && {
      rightActions: {
        ...(rdAssistantEnabled && {
          secondaryButton: {
            type: "primary",
            icon: "refresh",
            loading: isAssistantRequested,
            children: isAssistantRequested ? "Agent Running" : "Request Agent Review",
            onClick: () => doRequestAssistant(),
          },
        }),
        ctaButton: {
          type: "primary",
          icon: "plus",
          children: "New Expectation",
          disabled: !dataAssetId,
          onClick: () => setIsDrawerOpen(true),
        },
      },
    }),
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSource: data?.dataAsset?.datasourceV2,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab="overview" />,
  }
  const descriptionItems = useAssetSummaryDescriptionItems(data?.dataAsset)

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {dataAssetId && !isSujeEnabled && (
          <CreateExpectationDrawerContextProvider open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <CreateExpectationForAssetDrawer assetId={dataAssetId} assetName={data?.dataAsset?.name} />
          </CreateExpectationDrawerContextProvider>
        )}
        {dataAssetId && isSujeEnabled && (
          <SimpleNewExpectationDrawer
            dataAssetId={dataAssetId}
            open={isDrawerOpen}
            close={() => setIsDrawerOpen(false)}
          />
        )}
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <Card title={<Heading3>Data Asset Information</Heading3>}>
              <Descriptions column={1} layout="vertical" items={descriptionItems} />
            </Card>
          </Col>
          <Col xs={24} xl={18} className="sentry-mask">
            <Space direction="vertical" size="large" style={{ display: "flex" }}>
              {isSampleData && (
                <Alert
                  message={SAMPLE_DATA_OVERVIEW_TAB_MESSAGE}
                  description={
                    <div>
                      This dataset contains NYC yellow taxi trip records with fields capturing pick-up and drop-off
                      dates, times, locations, and fare amounts. Below are the profiled metrics of the Data Asset. Start
                      creating Expectations by clicking
                      <StyledIconTextContainer>
                        <StyledIcon name="plus" size="11px" />
                        New Expectation
                      </StyledIconTextContainer>
                      .
                    </div>
                  }
                  type="info"
                  showIcon
                />
              )}
              {dataAssetId && <MetricsTable assetId={dataAssetId} />}
            </Space>
          </Col>
        </Row>
      </MainContent>
    </PageHeader>
  )
}
