import { Flex, Radio } from "antd"
import styled from "styled-components"
import { RadioChangeEvent } from "antd/lib/radio/interface"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { useParams } from "react-router-dom"
import { DataAssetDetailTabKey } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { DataAssetWithRunsFragmentDoc } from "src/api/graphql/graphql-operations"
import { useApolloClient, useFragment_experimental, useQuery } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { useState } from "react"
import { SidebarCard } from "src/ui/Card/Card"
import { Button } from "src/ui/Button/Button"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useUrlParams } from "src/common/utils/url-params"
import { ViewModes } from "src/ui/Button/ViewToggle"
import { DeleteExpectationSuiteButton } from "src/DataAssets/AssetDetails/ExpectationSuiteSelector/DeleteExpectationSuiteButton"
import { graphql } from "src/api/graphql"
import { ExpectationSuiteDrawer } from "src/DataAssets/AssetDetails/Expectations/ExpectationSuiteDrawer"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`

const StyledRadio = styled(Radio)`
  padding: ${({ theme }) =>
    `${theme.spacing.vertical.xxs} ${theme.spacing.horizontal.xxs}
      `};
  margin-inline-end: 0;
  overflow: hidden;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColors.gxAccentBackground};
  }

  display: flex;
  align-items: center;
`

interface ExpectationSuiteSelectorProps {
  currentTab: DataAssetDetailTabKey
  onSelect?: () => void
}

export const ExpectationSuiteSelectorDocument = graphql(`
  query expectationSuiteSelector($options: ExpectationSuitesV2ListOptions) {
    expectationSuitesV2(options: $options) {
      id
      name
    }
  }
`)

export const ExpectationSuiteSelector = ({ currentTab, onSelect }: ExpectationSuiteSelectorProps) => {
  const isRoleEditor = useRequireRole("EDITOR")
  const sujeEnabled = useIsFeatureEnabled("sujeEnabled")
  const { navigateInOrg } = useOrganizationSlug()
  const [{ viewMode }] = useUrlParams<{ viewMode: ViewModes }>({ viewMode: "currentView" })
  const { expectationSuiteId = null, assetId = null } = useParams<{
    expectationSuiteId: string
    assetId: string
  }>()
  const { data, loading: isLoading } = useQuery(ExpectationSuiteSelectorDocument, {
    variables: { options: { filterByAssetRefId: assetId } },
  })
  const expectationSuites = data?.expectationSuitesV2 ?? []
  const hideSuitesSelector = sujeEnabled && expectationSuites.length === 1
  const showNewSuiteBtn = sujeEnabled ? false : isRoleEditor

  const { data: dataAsset } = useFragment_experimental({
    canonizeResults: true,
    fragment: DataAssetWithRunsFragmentDoc,
    fragmentName: (DataAssetWithRunsFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
    from: `AssetRef:${assetId}`,
  })

  const client = useApolloClient()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const handleClose = (expectationSuiteId: string | undefined) => {
    setIsDrawerVisible(false)
    if (expectationSuiteId) {
      client.refetchQueries({ include: [ExpectationSuiteSelectorDocument] })
      navigateInOrg(
        `/data-assets/${assetId}/expectations/expectation-suites/${expectationSuiteId}?viewMode=${viewMode}`,
      )
    }
  }

  const onSuiteSelected = (e: RadioChangeEvent) => {
    onSelect?.()
    navigateInOrg(
      `/data-assets/${assetId}/${currentTab}/expectation-suites/${e.target.value}${
        currentTab === "validations" ? `/results` : ""
      }?viewMode=${viewMode}`,
    )
  }

  const getTitle = () => {
    if (isLoading) return "Expectation Suites"
    return `Expectation Suites (${expectationSuites?.length})`
  }

  if (hideSuitesSelector) return null

  return (
    <>
      {dataAsset?.id && isRoleEditor && (
        <ExpectationSuiteDrawer
          dataAssetId={dataAsset.id}
          dataAssetName={dataAsset.name}
          isVisible={isDrawerVisible}
          onClose={handleClose}
        />
      )}
      <SidebarCard
        title={getTitle()}
        size="small"
        loading={isLoading}
        styles={isLoading ? {} : { body: { maxHeight: "200px", overflow: "scroll", padding: "0px" } }}
        extra={
          showNewSuiteBtn ? (
            <Button
              title="New Suite"
              name="New Suite"
              aria-label="New Suite"
              size="small"
              icon="plus"
              onClick={(e) => {
                showDrawer()
                e.stopPropagation()
              }}
            >
              New Suite
            </Button>
          ) : null
        }
      >
        {expectationSuites.length > 0 && (
          <StyledRadioGroup value={expectationSuiteId} onChange={onSuiteSelected}>
            {expectationSuites.map((suite) => {
              const other = expectationSuites.find((s) => s.id !== suite.id)?.id
              const redirectPath = other
                ? `/data-assets/${assetId}/${currentTab}/expectation-suites/${other}${currentTab === "validations" ? `/results` : ""}`
                : `/data-assets/${assetId}/overview`
              return (
                <Flex key={suite.id} align="center">
                  <StyledRadio value={suite.id}>{suite.name}</StyledRadio>
                  {isRoleEditor && (
                    <DeleteExpectationSuiteButton
                      expectationSuiteId={suite.id}
                      expectationSuiteName={suite.name}
                      redirectPath={redirectPath}
                    />
                  )}
                </Flex>
              )
            })}
          </StyledRadioGroup>
        )}
      </SidebarCard>
    </>
  )
}
