import { useEffect } from "react"
import { parseRowConditionStringToObject } from "src/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser"
import { GetDynamicStyleTagType } from "src/Expectation/utils/addDynamicStyledText"
import { BodyNormal } from "src/ui/typography/Text/Text"
import { useAnalytics } from "src/analytics/useAnalytics"
import { END_OF_CONDITION_STRING } from "src/common/config"

export const getFullRowCondition = (dynamicStyledText: React.ReactNode[]) => {
  if (typeof dynamicStyledText[0] === "string" && dynamicStyledText[0].trim() === "If") {
    dynamicStyledText.shift()
  }
  const index = dynamicStyledText.findIndex(
    (item: React.ReactNode) => typeof item === "string" && item.includes(END_OF_CONDITION_STRING),
  )
  const rowConditionString = dynamicStyledText
    .slice(0, index + 1)
    .join("")
    .replace(END_OF_CONDITION_STRING, "")
  const rowConditionObject = parseRowConditionStringToObject(rowConditionString)
  return rowConditionObject
    ? `${rowConditionObject.column} ${rowConditionObject.operator} ${rowConditionObject.parameter ?? ""}`.trim()
    : rowConditionString
}

export const RowCondition = ({
  dynamicStyledText,
  getDynamicStyleTag,
}: {
  dynamicStyledText: React.ReactNode[]
  getDynamicStyleTag: GetDynamicStyleTagType
}) => {
  const posthog = useAnalytics()

  useEffect(() => {
    posthog?.capture("Expectation with Conditional Param")
  }, [posthog])

  const fullRowCondition = getFullRowCondition(dynamicStyledText)

  return (
    <>
      <BodyNormal>{"If "}</BodyNormal>
      {getDynamicStyleTag(fullRowCondition)}
      <br />
    </>
  )
}
