import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils.tsx"
import { sampleDataSourceConfig } from "src/DataAssets/connect-to-data/schemas/data-source-schemas.ts"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled.ts"
import { useParams } from "react-router-dom"
import { graphql } from "src/api/graphql/gql"
import { useQuery } from "@apollo/client"

export const SampleDataDatasourceDocument = graphql(`
  query SampleDataDatasource($assetId: UUID!) {
    dataAsset(id: $assetId) {
      datasourceV2 {
        config
      }
    }
  }
`)

export function useIsSampleData() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const enabled = useIsFeatureEnabled("sampleDataEnabled")
  const response = useQuery(SampleDataDatasourceDocument, {
    variables: { assetId: assetId || "" },
    skip: !assetId,
  })
  if (!enabled || !response.data) {
    return false
  }
  const config =
    response.data?.dataAsset?.datasourceV2?.config &&
    parseJSONStringConfig(response.data?.dataAsset?.datasourceV2?.config)

  return (
    config &&
    typeof config === "object" &&
    "connection_string" in config &&
    config.connection_string === sampleDataSourceConfig.connectionString
  )
}
