import { Checkbox, Flex } from "antd"
import { Icon } from "src/ui/Icon"
import { Heading3 } from "src/ui/typography/Text/Text.tsx"

export function SelectDetectSchemaChanges() {
  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <Icon name="bolt" />
        <Heading3>Detect schema changes</Heading3>
      </Flex>
      <Checkbox defaultChecked={true}>
        Generate Expectations that ensure columns don’t change in selected Data Assets
      </Checkbox>
    </Flex>
  )
}
