import { Popover, Flex, Form, Select, Alert } from "antd"
import { SegmentedValue } from "antd/es/segmented"
import { useCallback, useState } from "react"
import { useTheme } from "styled-components"
import { useMutation, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"

import { Segmented } from "src/ui/Segmented/Segmented"
import { Button } from "src/ui/Button/Button"
import { DateSplitterMethodName, Metric } from "src/api/graphql/graphql"
import { UISplitterOptions } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { Heading3 } from "src/ui/typography/Text/Text"
import { Image } from "src/ui/Image"

export const EditBatchDataAssetWithLatestMetricRunDocument = graphql(`
  query EditBatchDataAssetWithLatestMetricRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      latestMetricRun {
        metrics {
          columnDataType
          columnName
        }
      }
    }
  }
`)

export function EditBatchPopover({
  dataAssetId,
  splitter,
}: {
  dataAssetId: string
  splitter?: UISplitterOptions | null
}) {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { data, loading } = useQuery(EditBatchDataAssetWithLatestMetricRunDocument, {
    variables: {
      id: dataAssetId ?? "",
    },
    skip: !dataAssetId,
  })

  const dateTimeColumns =
    !loading && data?.dataAsset?.latestMetricRun?.metrics
      ? getDateTimeColumns(data?.dataAsset?.latestMetricRun?.metrics ?? [])
      : []

  return (
    <Popover
      overlayInnerStyle={{ padding: theme.spacing.vertical.s }}
      placement="bottom"
      arrow={false}
      destroyTooltipOnHide
      title={
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <Flex gap={theme.spacing.horizontal.xxs}>
            <Image aria-label="batch" type="batch" />
            <Heading3>Define batch</Heading3>
          </Flex>
          <Flex justify="flex-end">
            <Button
              icon="close"
              type="text"
              iconSize="18px"
              aria-label="Close edit window"
              onClick={() => setIsOpen(false)}
            />
          </Flex>
        </Flex>
      }
      content={
        <EditBatchForm
          onClose={() => setIsOpen(false)}
          dateTimeColumns={dateTimeColumns}
          dataAssetId={dataAssetId}
          splitter={splitter}
          setIsOpen={setIsOpen}
        />
      }
      trigger="click"
      open={isOpen}
      onOpenChange={() => setIsOpen(true)}
    >
      <Button
        aria-label="Edit batch"
        onClick={() => setIsOpen(!isOpen)}
        style={{ marginRight: theme.spacing.horizontal.xs }}
      >
        Edit batch
      </Button>
    </Popover>
  )
}

const getDateTimeColumns = (metrics: Metric[]) => {
  const isTimeBasedTypeRegex = "(TIME|DATE)"
  return metrics.filter((metric) => metric.columnDataType?.match(isTimeBasedTypeRegex))
}

export const EditBatchDefinitionMutationDocument = graphql(`
  mutation EditBatchDefinition($input: EditBatchDefinitionInput!) {
    editBatchDefinition(input: $input) {
      __typename
    }
  }
`)

type BatchForm = { batchInterval: DateSplitterMethodName | "entire_asset"; batchColumn: string }
type EditBatchFormProps = {
  onClose: () => void
  dateTimeColumns: Metric[]
  dataAssetId: string
  splitter?: UISplitterOptions | null
  setIsOpen: (isOpen: boolean) => void
}

export function EditBatchForm({ onClose, dateTimeColumns, dataAssetId, splitter, setIsOpen }: EditBatchFormProps) {
  const theme = useTheme()
  const [form] = Form.useForm()
  const entireTableValue = "entire_asset"
  const hasDateTimeColumns = dateTimeColumns.length > 0
  const description = hasDateTimeColumns
    ? "Validate all records by selecting Entire Asset. Validate your Asset incrementally by selecting a time-based batch interval."
    : "Your Asset needs a column of datetime type to enable a time-based batch interval."
  const [editBatchDefinition, { loading, error }] = useMutation(EditBatchDefinitionMutationDocument)
  const [showColItem, setShowColItem] = useState(Boolean(splitter) && hasDateTimeColumns)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const handleFinish = useCallback(
    async (fields: BatchForm) => {
      if (!dataAssetId) {
        return // not a possibility; just assuring the compiler here
      }
      const { batchColumn, batchInterval } = fields
      const splitterConfig =
        batchInterval === entireTableValue ? null : { columnName: batchColumn, methodName: batchInterval }

      await editBatchDefinition({
        variables: {
          input: {
            assetId: dataAssetId,
            splitterConfig,
          },
        },
      })
      onClose()
    },
    [dataAssetId, editBatchDefinition, onClose],
  )

  const intervalOptions: {
    value: DateSplitterMethodName | "entire_asset"
    label: UISplitterOptions | "Entire Asset"
  }[] = [
    { value: entireTableValue, label: "Entire Asset" },
    { value: "SPLIT_ON_YEAR", label: UISplitterOptions.yearly },
    { value: "SPLIT_ON_YEAR_AND_MONTH", label: UISplitterOptions.monthly },
    { value: "SPLIT_ON_YEAR_AND_MONTH_AND_DAY", label: UISplitterOptions.daily },
  ]

  const defaultInterval = splitter ? methodNameMapping[splitter] : entireTableValue
  const handleIntervalChange = (value: SegmentedValue) => {
    value === entireTableValue ? setShowColItem(false) : setShowColItem(hasDateTimeColumns)
  }

  return (
    <>
      <Alert style={{ marginBottom: theme.spacing.vertical.s, width: "410px" }} description={description} />
      <Form<BatchForm> layout="vertical" onFinish={handleFinish} onFieldsChange={() => setIsFormTouched(true)}>
        <Form.Item
          name="batchInterval"
          label="Batch interval"
          rules={[{ required: true, message: "Batch interval is required" }]}
        >
          <Segmented
            defaultValue={defaultInterval}
            options={intervalOptions}
            onChange={handleIntervalChange}
            disabled={!hasDateTimeColumns}
          />
        </Form.Item>
        {showColItem && (
          <Form.Item
            name="validateBy"
            label="Validate by"
            rules={[{ required: true, message: "Validate by is required" }]}
          >
            <Select
              options={dateTimeColumns.map((metric) => ({
                value: metric.columnName,
                label: metric.columnName,
              }))}
              placeholder="Select a DATE or DATETIME column"
            />
          </Form.Item>
        )}
        <Form.Item {...(error && { validateStatus: "error", help: error.message })}>
          <Flex justify="flex-end" gap={theme.spacing.horizontal.xxs}>
            <Button
              aria-label="cancel"
              onClick={() => {
                setIsOpen(false)
                form.resetFields()
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={loading}
              disabled={!isFormTouched}
              htmlType="submit"
              aria-label="Update Batch"
            >
              Save
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}

const methodNameMapping: Record<string, DateSplitterMethodName> = {
  Year: "SPLIT_ON_YEAR",
  Month: "SPLIT_ON_YEAR_AND_MONTH",
  Day: "SPLIT_ON_YEAR_AND_MONTH_AND_DAY",
}
