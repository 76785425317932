/* eslint-disable react-refresh/only-export-components */ // FIXME
import { theme } from "src/ui/themes/theme"
import { VisualizationSpec } from "react-vega"
import { Config } from "vega-lite"
import { Chart } from "src/ui/Chart/Chart"
import { ChartConfig, vegaLiteSchema } from "src/ui/Chart/ChartConfig"
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations"
import {
  addIndexToData,
  getObservedValueFromExpectationValidationResult,
  getObservedValueTooltip,
  getLabelExpression,
  getParamValueFromStringValueType,
  getPrescriptiveSummaryFromExpectationValidationResult,
  getTickSizeExpression,
  getXAxisValues,
  sortData,
  CastRenderedContentValueResult,
  RenderedContentValue,
  getBatchIdFromDomainKwargs,
} from "src/Expectation/Charts/ChartUtils"
import { StringValueType } from "src/api/graphql/graphql"

export const NumericBetweenChart = (props: { validationResultCharts: Expectation_ValidationResultFragment[] }) => {
  const config: Config = ChartConfig()
  const sortedData = getDataForNumericBetweenExpectations(props.validationResultCharts)
  addIndexToData(sortedData)

  const domainMin: number | undefined = getDomain(sortedData, "min")
  const domainMax: number | undefined = getDomain(sortedData, "max")
  const adjustedDomains = adjustEdgeCaseDomainValues(domainMin, domainMax)
  addDomainsToData(sortedData, adjustedDomains.domainMin, adjustedDomains.domainMax)
  let scale = {}
  if (adjustedDomains.domainMin !== undefined && adjustedDomains.domainMax !== undefined) {
    scale = { domain: [adjustedDomains.domainMin, adjustedDomains.domainMax] }
  }

  const xAxisValues = getXAxisValues(sortedData)
  const labelExpression = getLabelExpression(sortedData)
  const tickSizeExpression = getTickSizeExpression(sortedData)
  const graphData = setValueLabels(sortedData)

  const spec: VisualizationSpec = {
    $schema: vegaLiteSchema,
    width: "container",
    height: 135,
    autosize: {
      type: "fit",
      resize: true,
      contains: "content",
    },
    config: config,
    data: { values: graphData },
    encoding: {
      x: {
        field: "index",
        type: "quantitative",
        axis: {
          title: "Runs",
          values: xAxisValues,
          labelExpr: labelExpression,
          tickSize: { expr: tickSizeExpression },
        },
      },
      y: {
        type: "quantitative",
        scale: scale,
        axis: {
          title: "Observed Value",
        },
      },
    },
    layer: [
      {
        mark: {
          type: "area",
          color: theme.colors.primaryColors.gxAccentBackground,
          opacity: 0.5,
          line: false,
        },
        encoding: {
          y: {
            field: "domainMax",
          },
          y2: {
            field: "maxValue",
          },
        },
      },
      {
        mark: {
          type: "area",
          color: theme.colors.neutralColorPalette.whites.white,
          opacity: 0,
        },
        encoding: {
          y: {
            field: "maxValue",
          },
          y2: {
            field: "minValue",
          },
        },
      },
      {
        mark: {
          type: "area",
          color: theme.colors.primaryColors.gxAccentBackground,
          opacity: 0.5,
        },
        encoding: {
          y: {
            field: "minValue",
          },
          y2: {
            field: "domainMin",
          },
        },
      },
      {
        mark: {
          type: "text",
          baseline: "bottom",
          dy: -2,
        },
        encoding: {
          y: {
            field: "maxValue",
          },
          text: {
            field: "maxValueLabel",
            type: "nominal",
          },
        },
      },
      {
        mark: {
          type: "text",
          baseline: "top",
          dy: 2,
        },
        encoding: {
          y: {
            field: "minValue",
          },
          text: {
            field: "minValueLabel",
            type: "nominal",
          },
        },
      },
      {
        mark: "point",
        encoding: {
          tooltip: [
            {
              title: "Success",
              field: "success",
              type: "nominal",
            },
            {
              title: "Run Time",
              field: "runtime",
              type: "temporal",
              format: "%m/%d/%y - %H:%M:%S",
            },
            {
              title: "Observed Value",
              field: "observedValueTooltip",
              type: "nominal",
            },
            {
              title: "Batch Name",
              field: "batchId",
              type: "nominal",
            },
          ],
          color: {
            type: "nominal",
            field: "success",
            legend: null,
            condition: [
              { test: "datum['success'] === true", value: theme.colors.success.gxSuccess },
              { test: "datum['success'] === false", value: theme.colors.error.gxError },
            ],
          },
          y: {
            field: "observedValue",
          },
        },
      },
    ],
  }

  return <Chart spec={spec} />
}

export function adjustEdgeCaseDomainValues(domainMin: number | undefined, domainMax: number | undefined) {
  const addedWhitespacePercent = 0.25

  // If min and max are the same, set one to zero and double the other
  // (to center the value in the range) or else the axis has no spread
  if (domainMax !== undefined && domainMax === domainMin) {
    if (domainMax === 0) {
      domainMax = domainMax + 1
    } else if (domainMax > 0) {
      domainMin = 0
      domainMax = domainMax * 2
    } else if (domainMax < 0) {
      domainMin = domainMin * 2
      domainMax = 0
    }
  }
  // Otherwise add whitespace beyond bounds
  // to prevent data points from being on the edge of the chart
  else {
    if (domainMin !== undefined && domainMax !== undefined) {
      const domain = domainMax - domainMin
      domainMin = domainMin - domain * addedWhitespacePercent
      domainMax = domainMax + domain * addedWhitespacePercent
    }
  }

  return { domainMin, domainMax }
}

export function getDataForNumericBetweenExpectations(validationChartResults: Expectation_ValidationResultFragment[]) {
  const values = validationChartResults.map((expectationValidationResult) => {
    const prescriptiveSummary: StringValueType =
      getPrescriptiveSummaryFromExpectationValidationResult(expectationValidationResult)
    const value: CastRenderedContentValueResult = getParamValueFromStringValueType(prescriptiveSummary, "value")
    const minValue: CastRenderedContentValueResult = getParamValueFromStringValueType(prescriptiveSummary, "min_value")
    const maxValue: CastRenderedContentValueResult = getParamValueFromStringValueType(prescriptiveSummary, "max_value")

    let fallbackType = typeof value.value
    if (fallbackType === "undefined") {
      fallbackType = minValue.value !== undefined ? typeof minValue.value : typeof maxValue.value
    }
    const observedValue: CastRenderedContentValueResult = getObservedValueFromExpectationValidationResult(
      expectationValidationResult,
      fallbackType,
    )
    const observedValueTooltip: RenderedContentValue = getObservedValueTooltip(observedValue)
    const batchId = getBatchIdFromDomainKwargs(expectationValidationResult)
    return {
      success: Boolean(expectationValidationResult.success),
      runtime: String(expectationValidationResult.runTime),
      observedValue: observedValue.value,
      observedValueTooltip: observedValueTooltip,
      minValue: value.value || minValue.value,
      maxValue: value.value || maxValue.value,
      minValueLabel: "",
      maxValueLabel: "",
      batchId: batchId,
    }
  })

  const sortedValues = sortData(values)

  // add data points to beginning and end for extending range to edge of chart
  sortedValues.values.unshift({
    success: Boolean(values[0].success),
    runtime: "",
    observedValue: undefined,
    observedValueTooltip: "",
    minValue: values[0].minValue,
    maxValue: values[0].maxValue,
    minValueLabel: "",
    maxValueLabel: "",
    batchId: "",
  })
  sortedValues.values.push({
    success: Boolean(values[values.length - 1].success),
    runtime: "",
    observedValue: undefined,
    observedValueTooltip: "",
    minValue: values[values.length - 1].minValue,
    maxValue: values[values.length - 1].maxValue,
    minValueLabel: "",
    maxValueLabel: "",
    batchId: "",
  })

  return sortedValues
}

type ObjectWithMinAndMax = {
  minValue: RenderedContentValue
  maxValue: RenderedContentValue
  observedValue: RenderedContentValue
  observedValueTooltip: RenderedContentValue
  minValueLabel?: string
  maxValueLabel?: string
}
function setValueLabels<T extends ObjectWithMinAndMax>(values: { values: T[] }) {
  const lastRecord = values.values[values.values.length - 1]
  let result = undefined
  if (lastRecord.maxValue !== undefined && lastRecord.minValue !== undefined) {
    result =
      lastRecord.maxValue !== lastRecord.minValue
        ? values.values.slice(0, values.values.length - 1).concat({
            ...values.values[values.values.length - 1],
            maxValueLabel: "max = " + lastRecord.maxValue,
            minValueLabel: "min = " + lastRecord.minValue,
          })
        : values.values.slice(0, values.values.length - 1).concat({
            ...values.values[values.values.length - 1],
            maxValueLabel: "value = " + lastRecord.maxValue,
          })
  } else if (lastRecord.minValue === undefined) {
    result = values.values.slice(0, values.values.length - 1).concat({
      ...values.values[values.values.length - 1],
      maxValueLabel: "max = " + lastRecord.maxValue,
    })
  } else if (lastRecord.maxValue === undefined) {
    result = values.values.slice(0, values.values.length - 1).concat({
      ...values.values[values.values.length - 1],
      minValueLabel: "min = " + lastRecord.minValue,
    })
  }
  return result === undefined ? values : result
}

export function getDomain(values: { values: ObjectWithMinAndMax[] }, minOrMax: "min" | "max"): number | undefined {
  let dataValue = undefined
  // This gets the minimum/maximum data element on the chart
  for (let i = 0; i < values.values.length; i++) {
    const runValue = minOrMax === "min" ? values.values[i].minValue : values.values[i].maxValue

    if (dataValue === undefined && typeof runValue === "number") {
      dataValue = runValue
    }
    const runObservedValue = values.values[i].observedValue
    let runDataValue = undefined
    if (typeof runValue === "number" && typeof runObservedValue === "number") {
      runDataValue = minOrMax === "min" ? Math.min(runValue, runObservedValue) : Math.max(runValue, runObservedValue)
    } else if (typeof runValue === "number") {
      runDataValue = runValue
    } else if (typeof runObservedValue === "number") {
      runDataValue = runObservedValue
    }
    let exceedsMin = undefined
    let exceedsMax = undefined
    if (runDataValue !== undefined && dataValue !== undefined) {
      exceedsMin = minOrMax === "min" && runDataValue < dataValue
      exceedsMax = minOrMax === "max" && runDataValue > dataValue
    }
    if (exceedsMin || exceedsMax || dataValue === undefined) {
      dataValue = runDataValue
    }
  }

  return dataValue
}

function addDomainsToData(
  values: { values: Array<Record<string, unknown> & { domainMin?: number; domainMax?: number }> },
  domainMin: number | undefined,
  domainMax: number | undefined,
) {
  // TODO: make pure function
  values.values.forEach((item) => {
    item["domainMin"] = domainMin
    item["domainMax"] = domainMax
  })
}
