/* eslint-disable react-refresh/only-export-components */ // FIXME
import { SegmentedValue } from "antd/es/segmented"
import { Segmented } from "src/ui/Segmented/Segmented"

const ActiveListTypes = ["all", "failures"] as const
export type ActiveListType = (typeof ActiveListTypes)[number]
type ActiveListTypeOption = { label: string; value: string; disabled: boolean }

interface FailuresToggleProps {
  onToggleClick: (selection: ActiveListType) => void
  defaultValue?: ActiveListType
  disabled: boolean
}

export const options: { label: string; value: ActiveListType }[] = [
  { label: "Show All", value: "all" },
  { label: "Failures Only", value: "failures" },
]

export const FailuresToggle = ({ onToggleClick, defaultValue, disabled = false }: FailuresToggleProps) => {
  return (
    <Segmented
      block={true}
      options={
        options.map((option) => ({
          label: option.label,
          value: option.value,
          disabled: disabled,
        })) satisfies ActiveListTypeOption[]
      }
      defaultValue={defaultValue || options[0].value}
      disabled={disabled}
      onChange={(e: SegmentedValue) => {
        onToggleClick(e as ActiveListType)
      }}
    />
  )
}
